import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const VIPSchema = Yup.object().shape({
  nume: Yup.string().required('Numele este un camp obligatoriu.'),
  telefon: Yup.string()
    .test(
      'lungime',
      'Numarul trebuie să conțină minim 10 cifre',
      val => val.toString().length >= 10
    )
    .required('Numărul de telefon este un câmp obligatoriu.'),
  email1: Yup.string()
    .email('Email-ul trebuie sa fie valid.')
    .required('Email-ul este un camp obligatoriu.'),
  email2: Yup.string()
    .email('Email-ul trebuie sa fie valid.')
    .required('Email-ul este un camp obligatoriu.'),
});

export default VIPSchema;

import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  Image,
  Center,
  Flex,
  VStack,
  Spacer,
  Select,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FaTelegram, FaYoutube } from 'react-icons/fa';
import { Formik, Form, Field } from 'formik';
import teamOnePhoto from '../../images/rapid.png';
import banner from '../../images/banner_lv.png';
import teamTwoPhoto from '../../images/vip/fcsb.png';
import PronoSchema from './PronoSchema';
import axios from 'axios';

import PronoDataService from '../../services/prono-services';

function PronoForm() {
  const [wasSuccessfullySumbitted, setWasSuccessfullySumbitted] =
    useState(false);
  const [errorsAppeared, setErrorsAppeared] = useState(false);

  const [formIsLoading, setFormIsLoading] = useState(false);

  const [promotionStopped, setPromotionStopped] = useState(true);

  const submitForm = async (values, resetForm) => {
    setFormIsLoading(true);

    const opt = {
      nume: values.nume,
      prenume: values.prenume,
      email: values.email,
      scor: values.scor1 + '-' + values.scor2,
    };

    try {
      let ref = await PronoDataService.addPronos(opt);
      setFormIsLoading(false);
      setWasSuccessfullySumbitted(true);
      setErrorsAppeared(false);
      resetForm();
    } catch (e) {
      setWasSuccessfullySumbitted(false);
      setErrorsAppeared(true);
    }

    // console.log(opt);
    // let headers = {
    //   'Access-Control-Allow-Headers': '*', // this will allow all CORS requests
    //   'Access-Control-Allow-Methods': 'OPTIONS,POST,GET', // this states the allowed methods
    //   'Content-Type': 'application/json', // this shows the expected content type
    // };
    // axios
    //   .post('https://tipzor-api.alevla.com/matchday', opt, headers)
    //   .then(response => {
    //     console.log(response);
    //     setFormIsLoading(false);
    //     if (response.data === 'OK') {
    //       setWasSuccessfullySumbitted(true);
    //       setErrorsAppeared(false);
    //       resetForm();
    //     } else {
    //       setWasSuccessfullySumbitted(false);
    //       setErrorsAppeared(true);
    //     }
    //   });
  };

  return (
    <Container
      maxW="lg"
      py={{
        base: '12',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <a href="https://gnttips.ro/LasVegas">
        <Image borderRadius={'15px'} maxWidth={'100%'} src={banner} />
      </a>
      <Heading pt={8} textAlign={'center'} size="md">
        Fii printre primii 20 care indica scorul corect si câstigă un FREEBET in
        valoare de 25 RON!
      </Heading>
      <Heading pb={8} textAlign={'center'} size="sm">
        Deschide un cont Las Vegas{' '}
        <Text as={'a'} color={'red'}>
          <u>
            {' '}
            <a href="https://gnttips.ro/LasVegas">AICI</a>!
          </u>
        </Text>
      </Heading>
      <Stack spacing="8">
        <Flex justifyContent={'center'}>
          <VStack>
            <Box p={0} m={0} height={1} />
            <Image m={0} height={'199px'} src={teamOnePhoto} />
            <Heading size={'md'}>Rapid</Heading>
          </VStack>
          <Spacer />
          <VStack>
            <Box height={'200px'}></Box>
            <Heading size={'md'}>-</Heading>
          </VStack>
          <Spacer />
          <VStack>
            <Box p={0} m={0} height={'20px'} />
            <Image m={0} height={'180px'} src={teamTwoPhoto} />
            <Heading size={'md'}>FCSB</Heading>
          </VStack>
        </Flex>

        <Box
          py={{
            base: '0',
            sm: '8',
          }}
          px={{
            base: '4',
            sm: '10',
          }}
          bg={{
            base: 'transparent',
            sm: 'bg-surface',
          }}
          boxShadow={{
            base: 'none',
            sm: 'md',
          }}
          borderRadius={{
            base: 'none',
            sm: 'xl',
          }}
        >
          <Stack spacing="6">
            {promotionStopped && (
              <>
                <Heading size="lg" color={'red'} textAlign="center">
                  Meciul a început!
                </Heading>
                <Heading size="md" color={'red'} textAlign="center">
                  Din păcate nu ne mai poți trimite pronosticul tău.
                </Heading>
              </>
            )}

            {wasSuccessfullySumbitted && (
              <>
                <Heading size="lg" color={'green'} textAlign="center">
                  Felicitari!
                </Heading>
                <Heading size="md" color={'green'} textAlign="center">
                  Pronosticul tău a fost înregistrat cu succes!
                </Heading>
                <Heading size={'sm'} textAlign="center">
                  Nu uita să validezi participarea în concurs! <br />
                  <br />
                  Alătură-te grupului de Telegram sau dă-i subscribe la canalul
                  de YouTube.
                </Heading>
                <HStack>
                  <Spacer />
                  <a href="https://t.me/BettingTipsGnT">
                    <FaTelegram
                      style={{
                        width: '40px',
                        height: '40px',
                        color: '#0088cc',
                      }}
                    />
                  </a>
                  <Box w={3} />
                  <a href="https://youtube.com/@GnT_TV">
                    <FaYoutube
                      style={{
                        width: '40px',
                        height: '40px',
                        color: '#ff0000',
                      }}
                    />
                  </a>
                  <Spacer />
                </HStack>
              </>
            )}

            {errorsAppeared && (
              <>
                <Heading size="lg" color={'red'} textAlign="center">
                  Oops...
                </Heading>
                <Heading size="md" color={'red'} textAlign="center">
                  Din păcate a apărut o eroare. Te rugăm încearcă din nou.
                </Heading>
              </>
            )}

            {promotionStopped === false &&
            wasSuccessfullySumbitted === false ? (
              <>
                <Formik
                  initialValues={{
                    nume: '',
                    prenume: '',
                    email: '',
                    scor1: '',
                    scor2: '',
                  }}
                  onSubmit={(values, { resetForm }) => {
                    submitForm(values, resetForm);
                  }}
                  validationSchema={PronoSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    dirty,
                    isValid,
                    setFieldValue,
                  }) => {
                    return (
                      <Form>
                        <FormControl
                          isInvalid={errors.nume && touched.nume}
                          mb={8}
                          variant="floating"
                          isRequired
                        >
                          <FormLabel>Nume</FormLabel>
                          <Field
                            as={Input}
                            name="nume"
                            value={values.nume}
                            placeholder=" "
                          ></Field>

                          {errors.nume && touched.nume ? (
                            <FormErrorMessage>{errors.nume}</FormErrorMessage>
                          ) : null}
                        </FormControl>
                        <FormControl
                          isInvalid={errors.prenume && touched.prenume}
                          mb={8}
                          variant="floating"
                          isRequired
                        >
                          <FormLabel>Prenume</FormLabel>
                          <Field
                            as={Input}
                            name="prenume"
                            value={values.prenume}
                            placeholder=" "
                          ></Field>

                          {errors.prenume && touched.prenume ? (
                            <FormErrorMessage>
                              {errors.prenume}
                            </FormErrorMessage>
                          ) : null}
                        </FormControl>
                        <FormControl
                          isInvalid={errors.email && touched.email}
                          mb={8}
                          variant="floating"
                          isRequired
                        >
                          <FormLabel>Email</FormLabel>
                          <Field
                            as={Input}
                            type="email"
                            name="email"
                            value={values.email}
                            placeholder=" "
                          ></Field>

                          {errors.email && touched.email ? (
                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                          ) : null}
                        </FormControl>
                        <Flex>
                          <FormControl
                            maxW={'45%'}
                            isInvalid={errors.scor1 && touched.scor1}
                            mb={8}
                            variant="floating"
                            isRequired
                          >
                            <FormLabel>Scor Rapid</FormLabel>
                            <Field
                              as={Select}
                              name="scor1"
                              value={values.scor1}
                              placeholder=" "
                            >
                              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(e => {
                                return (
                                  <option key={e} value={e}>
                                    {e}
                                  </option>
                                );
                              })}
                            </Field>

                            {errors.scor1 && touched.scor1 ? (
                              <FormErrorMessage>
                                {errors.scor1}
                              </FormErrorMessage>
                            ) : null}
                          </FormControl>
                          <Spacer />

                          <FormControl
                            maxW={'45%'}
                            isInvalid={errors.scor2 && touched.scor2}
                            mb={8}
                            variant="floating"
                            isRequired
                          >
                            <FormLabel>Scor FCSB</FormLabel>
                            <Field
                              as={Select}
                              name="scor2"
                              value={values.scor2}
                              placeholder=" "
                            >
                              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(e => {
                                return (
                                  <option key={e} value={e}>
                                    {e}
                                  </option>
                                );
                              })}
                            </Field>

                            {errors.scor2 && touched.scor2 ? (
                              <FormErrorMessage>
                                {errors.scor2}
                              </FormErrorMessage>
                            ) : null}
                          </FormControl>
                        </Flex>
                        <Stack spacing="6">
                          <Button
                            isLoading={formIsLoading}
                            type="submit"
                            variant="solid"
                            colorScheme={'red'}
                          >
                            Trimite pronostic
                          </Button>
                        </Stack>
                      </Form>
                    );
                  }}
                </Formik>
                <Heading size={'sm'}>
                  <sup>*</sup>Pentru a valida participarea în concurs intră pe
                  grupul nostru de Telegram sau dă-i subscribe la canalul nostru
                  de YouTube.
                </Heading>
                <HStack>
                  <Spacer />
                  <a href="https://t.me/BettingTipsGnT">
                    <FaTelegram
                      style={{
                        width: '40px',
                        height: '40px',
                        color: '#0088cc',
                      }}
                    />
                  </a>
                  <Box w={3} />
                  <a href="https://youtube.com/@GnT_TV">
                    <FaYoutube
                      style={{
                        width: '40px',
                        height: '40px',
                        color: '#ff0000',
                      }}
                    />
                  </a>
                  <Spacer />
                </HStack>
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
}

export default PronoForm;

import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const PronoSchema = Yup.object().shape({
  nume: Yup.string().required('Numele este un camp obligatoriu.'),
  prenume: Yup.string().required('Prenumele este un camp obligatoriu.'),
  email: Yup.string()
    .email('Email-ul trebuie sa fie valid.')
    .required('Email-ul este un camp obligatoriu.'),
  scor1: Yup.number()
    .max(9, 'Maxim 9 goluri')
    .min(0, 'Minim 0 goluri')
    .required('Scorul primei echipe este  un camp obligatoriu.'),
  scor2: Yup.number()
    .max(9, 'Maxim 9 goluri')
    .min(0, 'Minim 0 goluri')
    .required('Scorul celei de-a doua echipe este un camp obligatoriu.'),
});

export default PronoSchema;

import { db } from './firebaseApp';

import { addDoc, collection } from 'firebase/firestore';

const pronoCollectionRef = collection(db, 'rapid-fcsb');

class PronoDataService {
  addPronos = newProno => {
    console.log(newProno);
    return addDoc(pronoCollectionRef, newProno);
  };
}

export default new PronoDataService();

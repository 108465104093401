import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';
import PronoForm from './components/Form/Form';
import VipForm from './components/Form/VipForm';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <PronoForm />
    </ChakraProvider>
  );
}

export default App;
